import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Form from "./Form";
import Report from "./Report";
import "./App.css";
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Switch>
        <Route path="/" exact strict component={Form} />
        <Route path="/report" exact strict component={Report} />
      </Switch>
    );
  }
}

export default App;
